import {copyContent, downloadTxt} from "../lib/fileUtils";
import {CloudDownloadIcon, DocumentDuplicateIcon, SparklesIcon} from "@heroicons/react/solid";
import React from "react";
import {beautifyJson} from "../lib/jsonUtils";

interface JsonInputProps {
    id: string;
    value: string;
    onChange: (v: string) => void;
}

const JsonInput: React.FC<JsonInputProps> = ({id, value, onChange}) => {
    return <form className={"w-full"}>
        <div className="ml-auto py-2 flex justify-end space-x-5">
            <div className="flex items-center">
                <button
                    type="button"
                    className="-m-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full text-primary-400 hover:text-primary-500"
                    onClick={() => copyContent(value)}
                >
                    <span className="sr-only">Copy</span>
                    <DocumentDuplicateIcon className="h-5 w-5" aria-hidden="true"/>
                </button>
            </div>
            <div className="flex items-center">
                <button
                    type="button"
                    className="-m-2.5 inline-flex h-10 w-15 items-center justify-center rounded-full text-primary-400 hover:text-primary-500"
                    onClick={() => downloadTxt(`left-${Date.now().toString()}`, value)}
                >
                    <span className="sr-only">Download</span>
                    <CloudDownloadIcon className="h-5 w-5" aria-hidden="true"/>
                </button>
            </div>
            <div className="flex items-center">
                <button
                    type="button"
                    className="-m-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full text-warning-400 hover:text-warning-500"
                    onClick={() => onChange(beautifyJson(value))}
                >
                    <span className="sr-only">Beautify</span>
                    <SparklesIcon className="h-5 w-5" aria-hidden="true"/>
                </button>
            </div>
        </div>
        <textarea
            rows={5}
            name={id}
            id={id}
            className="block w-full max-h-96 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Paste json..."
            value={value}
            onChange={e => onChange(e.target.value)}
        />
    </form>
}

export default JsonInput;