export const copyContent = async (content: string) => {
    try {
        await navigator.clipboard.writeText(content);
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
}

export const downloadFile = (filename: string, fileType: string, body: string) => {
    const blob = new Blob([body], {type: fileType});
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export const downloadTxt = (filename: string, body: string, fileType = 'text/plain;charset=utf-8;') => {
    downloadFile(filename, fileType, body)
}