export const unescapeJson = (s: string) => {
    try {
        return JSON.parse(s);
    } catch (e) {
        console.log(e);
        return s
    }
}

export const beautifyJson = (s: string) => {
    const t1 = unescapeJson(s);
    const t2 = unescapeJson(t1);
    return JSON.stringify(t2, null, 2)
}