import React, {useState} from 'react';
import JsonInput from "./components/JsonInput";
import ReactDiffViewer, {DiffMethod} from "react-diff-viewer";

function App() {
    const [leftInput, setLeftInput] = useState("");
    const [rightInput, setRightInput] = useState("");

    return (
        <div>
            <div className={"columns-2 gap-16 p-4 place-items-end"}>
                <JsonInput id={'left-input'} value={leftInput} onChange={setLeftInput}/>
                <JsonInput id={'left-input'} value={rightInput} onChange={setRightInput}/>
            </div>
            <div className={"p-4 justify-around"}>
                <ReactDiffViewer
                    oldValue={leftInput}
                    newValue={rightInput}
                    splitView={true}
                    compareMethod={DiffMethod.WORDS_WITH_SPACE}
                />
            </div>
        </div>
    );
}

export default App;
